import React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '@helpers/useSiteMetadata'

const Seo = ({
  title,
  description,
  excerpt,
  meta,
  keywords,
  author,
  date,
  timeToRead,
  children,
  thumbnail,
  siteUrl
}) => {
  const site = useSiteMetadata()

  const social = (author && author.social) || site.social || []
  const twitter =
    social.find(s => s.name && s.name.toLowerCase() === 'twitter') || {}

  description = excerpt || description || site.description

  thumbnail = thumbnail && thumbnail.hero && thumbnail.hero.src
  const thumbnailUrl = thumbnail && siteUrl && `${siteUrl}${thumbnail}`

  const metaTags = [
    { itemprop: 'name', content: site.title },
    { itemprop: 'description', content: description },
    { name: 'description', content: description },

    { property: 'og:title', content: site.title },
    { property: 'og:description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.name },
    { property: 'og:image', content: thumbnailUrl },

    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: site.name },
    { name: 'twitter:title', content: site.title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: twitter.url }
  ]

  if (keywords && keywords.length > 0) {
    metaTags.push({ name: 'keywords', content: keywords.join(', ') })
  }

  if (date) {
    metaTags.push({ name: 'article:published_time', content: date })
  }

  if (timeToRead) {
    metaTags.push({ name: 'twitter:label1', value: 'Reading time' })
    metaTags.push({ name: 'twitter:data1', value: `${timeToRead} min read` })
  }

  if (meta) {
    metaTags.push(meta)
  }

  if (title === "Home") {
    return (
      <Helmet
        htmlAttributes={{
          lang: 'en'
        }}
        title={title}
        titleTemplate={`${site.title}`}
        meta={metaTags}
      >
          { /* Mailchimp popup */ }
          <script id="mcjs">{`
          !function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/65d6e7426ca1c5af27067295e/07388705ccc4c62c76db733aa.js");
          `}</script>
          { /* HubSpot Embed Code */ }
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/9165805.js"></script>
        {children}
      </Helmet>
    )
  }
  else {
    return (
      <Helmet
        htmlAttributes={{
          lang: 'en'
        }}
        title={title}
        titleTemplate={`%s | ${site.title}`}
        meta={metaTags}
      >
        {children}
      </Helmet>
    )
  }
}

export default Seo
